@import "src/scss-commons/variables";
@import "src/scss-commons/breakpoints";

.root{
  cursor: pointer;
  &:hover{
    box-shadow: 0 4px 4px 2px rgba($primary, 0.25);
    border-color: $primary;
  }
}
.profilePic {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  object-fit: cover;
}

.content {
  flex-basis: 0;
  @include respond-below(sm) {
    flex-basis: auto;
  }

  h6 {
    font: {
      weight: 500;
      size: 16px;
    }
    line-height: 20px;
    color: $card-black;
  }

  p {
    font: {
      weight: 400;
      size: 14px;
    }
    line-height: 17px;
    color: $card-grey;

    span{
      color: $card-black;
    }
  }

  i {
    background-color: $card-grey;
    height: 14px;
    width: 14px;
  }

  .subheader,.experience{
    height: 17px;
  }

  .address{
    height: 34px;
  }
}

.moreButton {
  z-index: 999;
  border: none;
  background: $grey !important;
  color: $primary !important;
  padding: 10px 14px !important;
  box-shadow: none !important;
  top: 10px;
  right: 16px;

  img{
    margin-left: 0!important;
  }

  &:hover{
    background: rgba($dark-blue,0.7) !important;
    color: $white !important;
  }

  &:active{
    background: $dark-blue !important;
    color: $white !important;
  }
}

.hired{
  text-align: right;
  top: 11px;
  right: 16px;
  color: $primary;

  img{
    background: $primary;
    height: 15px;
    width: 15px;
    align-self: center;
  }
}
