@import "src/scss-commons/variables";
@import "src/scss-commons/breakpoints";

.root {
  :global(.modal-dialog) {
    max-width: 800px;
  }

  :global(.modal-content) {
    border-radius: 10px;
    border: 0;
  }

  :global(.modal-header) {
    padding: 30px;
    @include respond-below(sm) {
      padding: 16px;
    }
  }

  :global(.modal-body) {
    padding: 30px;
    @include respond-below(sm) {
      padding: 16px;
    }
  }

}

.profilePic {
  width: 120px;
  height: 160px;
  border-radius: 10px;
  object-fit: cover;
  @include respond-below(sm) {
    width: 60px;
    height: 80px;
    margin-right: 16px;
  }
}

.content {
  flex-basis: 0;
  @include respond-below(sm) {
    flex-basis: auto;
  }

  h4 {
    font: {
      weight: 500;
      size: 32px;
    }
    line-height: 120%;
    color: $card-black;
    margin-bottom: 4px;
    @include respond-below(sm) {
      font-size: 28px;
      line-height: 28.8px;
      margin-bottom: 4px;
    }
  }

  h5 {
    font: {
      weight: 400;
      size: 20px;
    }
    line-height: 150%;
    color: $card-grey;
    margin-bottom: 16px;
    @include respond-below(sm) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  h6 {
    font: {
      weight: 400;
      size: 16px;
    }
    line-height: 150%;
    color: $card-grey;
  }

  p {
    font: {
      weight: 400;
      size: 14px;
    }
    color: $card-grey;
    line-height: 24px;
    //margin-bottom: 16px;
  }

  i {
    background-color: $card-grey;
    height: 14px;
    width: 14px;
  }
}



.reviews{
  h6{
    font: {
      weight: 500;
      size: 21px;
    }
    line-height: 26.25px;
    color: $card-black;
    @include respond-below(sm) {
      font-size: 18px;
      line-height: 22.5px;
    }
  }
}
