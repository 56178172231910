@import "src/scss-commons/variables";
@import "src/scss-commons/breakpoints";

.root {
  background: $white;
  border-radius: 6px;
  box-shadow: 0 4px 4px 2px rgba(0,0,0,.25);
  padding: 16px 24px;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  @include respond-below(sm) {
    padding: 16px;
  }
}

.beta{
  font-size: 0.8rem !important;
  top: 10px;
  right: 15px;
}
