@import "src/scss-commons/variables";
@import "src/scss-commons/utils";

#rui__header {
  border-bottom: 1px solid $border-dark;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  background: $white;
  z-index: 999999;
  top: 0;

  .beta{
    font: {
      family: 'Caveat', cursive;
      size: 16px;
    }
  }

  &.relative {
    position: relative;
  }

  .hamburger,
  .timeline {
    position: absolute;
    z-index: 9999;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;

    &.is-open {
      border-bottom: 1px solid #F15C53;

      img {
        background: #F15C53;
        margin-top: 1px;
      }
    }

    @media (min-width: 768px) {
      display: none;
    }

    img {
      height: 25px;
    }
  }

  .timeline {
    right: 0;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    background: transparent;
    border: none;
    padding: 80px 113px;

    &.push--top {
      padding: 0;
    }
  }

  .logo {
    width: 135px;
  }

  .always-show {
    display: block !important;
  }

  .logo-subline {
    font-size: 15px;
    margin-top: 8px;
    line-height: 19px;
    margin-bottom: 0;
  }
}
