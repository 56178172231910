@import "src/scss-commons/variables";

.reviewContainer {
  padding: 0 9px;
}

.review {
  border: 1px solid $dark-grey;
  border-radius: 10px;
  padding: 28px;
  margin-bottom: 20px;
  background: $white;

  h6 {
    margin-bottom: 0;
  }

  & > div {
    margin: 0;
  }
}

.scoreChip {
  background-color: #f4be52;
  color: white;
  font-size: 0.925rem;
  font-weight: 500;
  line-height: 1.2;
  padding: 6px 10px;
  width: fit-content;
  border-radius: 4px;
  margin-right: 8px;
}

.starsContainer {
  flex-grow: 1;
}
