@import "src/scss-commons/variables";

.horizontal-center {
  justify-content: center;
  text-align: center;
}

.vertical-center {
  align-items: center;
}

.centered {
  align-content: center;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.backdrop {
  height: 100%;
  width: 100%;
  background: black;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.3;
}

.flexbox {
  display: flex;
}

.flexbox-col {
  display: flex;
  flex-direction: column;
}

.no--gutters {
  margin: 0;
}

.dashboard--gutters {
  margin: 70px 15px 0 !important;

  @media (min-width: 768px) {
    margin: 0 30px 0 120px !important;
  }
}

.icon-apartment {
  mask: url('/images/apartment.svg');
}

.png.icon-townhouse {
  background-image: url('/images/townhouse.png') !important;
}

.icon-single-family {
  mask: url('/images/single-family.svg');
}

.png.icon-multi-family {
  background-image: url('/images/multi-family.png') !important;
}

.icon-primary-house {
  mask: url('/images/primary-house.svg');
}

//.icon-secondary-house {
//  mask: url('/images/secondary-house.svg');
//}

.png.icon-secondary-house {
  background-image: url('/images/secondary-house.png') !important;
}

.icon-rental-house {
  mask: url('/images/rental-house.svg');
}

.icon-check {
  mask: url('/images/check.svg');
}

.icon-cross {
  mask: url('/images/cross.svg');
}

.icon-dashboard {
  mask: url('/images/dashboard.svg');
}

.icon-timeline {
  mask: url('/images/timeline.svg');
}

.icon-reminders {
  mask: url('/images/reminders.svg')
}

.icon-marketplace {
  mask: url('/images/marketplace.svg');
}

.icon-summary {
  mask: url('/images/summary.svg');
}

.icon-documents {
  mask: url('/images/documents.svg');
}

.icon-profile {
  mask: url('/images/profile.svg');
}

.icon-settings {
  mask: url('/images/settings.svg');
}

.icon-support {
  mask: url('/images/support.svg');
}

.icon-logo {
  mask: url('/images/hyponia-logo-only.svg')
}

.icon-hamburger {
  mask: url('/images/hamburger.svg')
}

.icon-user {
  mask: url('/images/user.svg');
}

.icon-pin {
  mask: url('/images/pin.svg');
}

.icon-at-sign {
  mask: url('/images/at-sign.svg');
}

.icon-phone {
  mask: url('/images/phone.svg');
}

.icon-message {
  mask: url('/images/message.svg');
}

.icon-mail {
  mask: url('/images/mail.svg');
}

.icon-incomplete {
  mask: url('/images/incomplete.svg');
}

.icon-paperclip {
  mask: url('/images/paperclip.svg');
}

.icon-edit {
  mask: url('/images/edit.svg');
}

.icon-plus {
  mask: url('/images/plus.svg');
}

.icon-caret {
  mask: url('/images/caret.svg');
}
.icon-get-pre-approved,
.icon-note {
  mask: url('/images/note.svg');
}

.icon-complete {
  mask: url('/images/complete.svg');
}

.icon-equilizer {
  mask: url('/images/equilizer.svg');
}

.icon-hyperlink {
  mask: url('/images/hyperlink.svg');
}

.icon-external-link {
  mask: url('/images/external-link.svg');
}

.icon-timeline-hamburger {
  mask: url('/images/timeline-hamburger.svg');
}

.icon-rounded-check {
  mask: url('/images/rounded-check.svg');
}

.icon-attorneys {
  mask: url('/images/attorneys.svg');
}

.icon-mortgage-application,
.icon-mortgage,
.icon-bank-statement {
  mask: url('/images/bank-statement.svg');
}

.icon-business-agent {
  mask: url('/images/business-agent.svg');
}

.icon-document-edit {
  mask: url('/images/document-edit.svg');
}

.icon-home-inspection,
.icon-inspection,
.icon-home-inspectors {
  mask: url('/images/home-inspectors.svg');
}

.icon-lenders {
  mask: url('/images/lenders.svg');
}

.icon-make-an-offer,
.icon-offer,
.icon-loan {
  mask: url('/images/loan.svg');
}

.icon-move,
.icon-movers {
  mask: url('/images/movers.svg');
}

.icon-find-a-property,
.icon-property,
.icon-property-location {
  mask: url('/images/property-location.svg');
}

.icon-property-search {
  mask: url('/images/property-search.svg');
}

.icon-real-estate-agents {
  mask: url('/images/real-estate-agents.svg');
}

.icon-get-insurance,
.icon-insurance,
.icon-homeowners-insurance{
  mask: url("/images/insurance.svg");
}

.icon-closing {
  mask: url('/images/real-estate.svg');
}

.icon-trash {
  mask: url('/images/trash.svg');
}

.icon-view {
  mask: url('/images/view.svg');
}

.icon-talk-to-lenders {
  mask: url('/images/talk-to-lenders.svg');
}

.icon-final-walkthrough {
  mask: url('/images/final-walkthrough.svg');
}

.icon-sign-contract {
  mask: url('/images/sign-contract.svg');
}

.icon-professionals-dashboard {
  mask: url('/images/professionals-dashboard.svg');
}

.icon-leads {
  mask: url('/images/leads.svg');
}

.icon-deals {
  mask: url('/images/deals.svg');
}

.icon-reviews {
  mask: url('/images/reviews.svg');
}

.icon {
  display: inline-block;
  background: $text-dark;
  height: 50px;
  width: 50px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}

.icon.png {
  height: 55px;
  width: 55px;
  background: transparent;
  background-size: cover;
}


.btn.disabled,
.btn:disabled {
  background: $border-dark;
  border-color: $border-dark;
}
