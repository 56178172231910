$text-dark: #292D45;
$text-light: #898A93;
$background: #F9F9FA;
$grey: #DCE7FD;
$white: #FFFFFF;
$black: #000000;
$primary: #5289F4;
$thin: 100;
$extraLight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$demiBold: 600;
$bold: 700;
$extraBold: 800;
$red: #ff0000;
$link-color: #74788E;
$dark-blue: #0062cc;
$dark-grey: #CDCDCD;

$border-dark: $dark-grey;
$border-light: #F3F3F3;
$border-selected: $primary;

$sidebar-color: #F5F5F5;

$card-black: #373737;
$card-grey: #737687;


.text-dark {
  color: $text-dark;
}

.red{
  color: $red;
}
