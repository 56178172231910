@import "src/scss-commons/variables";

.reviews {
  padding: 32px 16px 4px;
  width:100%;

  p {
    margin-bottom: 0;
  }

}

.reviewsRow {
  padding: 0 6px;
}
