@font-face {
  font-family: 'TTInterfaces';
  src: url('/fonts/ttinterfaces/TTInterfaces-Thin.ttf');
  src:
          local('Lovely Font'),
          local('Lovely-Font'),
          url('/fonts/ttinterfaces/TTInterfaces-Thin.otf')
          format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'TTInterfaces';
  src: url('/fonts/ttinterfaces/TTInterfaces-ExtraLight.ttf');
  src:
          local('Lovely Font'),
          local('Lovely-Font'),
          url('/fonts/ttinterfaces/TTInterfaces-ExtraLight.otf')
          format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'TTInterfaces';
  src: url('/fonts/ttinterfaces/TTInterfaces-Light.ttf');
  src:
          local('Lovely Font'),
          local('Lovely-Font'),
          url('/fonts/ttinterfaces/TTInterfaces-Light.otf')
          format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'TTInterfaces';
  src: url('/fonts/ttinterfaces/TTInterfaces-Regular.ttf');
  src:
          local('Lovely Font'),
          local('Lovely-Font'),
          url('/fonts/ttinterfaces/TTInterfaces-Regular.otf')
          format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'TTInterfaces';
  src: url('/fonts/ttinterfaces/TTInterfaces-Medium.ttf');
  src:
          local('Lovely Font'),
          local('Lovely-Font'),
          url('/fonts/ttinterfaces/TTInterfaces-Medium.otf')
          format('opentype');
  font-weight: 500;
}

/*bold version*/
@font-face {
  font-family: 'lovelyFont';
  src: url('/fonts/ttinterfaces/TTInterfaces-DemiBold.ttf');
  src:
          local('Lovely Font Bold'),
          local('Lovely-Font-Bold'),
          url('/fonts/ttinterfaces/TTInterfaces-DemiBold.otf')
          format('opentype');
  font-weight: 600;
}

/*bold version*/
@font-face {
  font-family: 'lovelyFont';
  src: url('/fonts/ttinterfaces/TTInterfaces-Bold.ttf');
  src:
          local('Lovely Font Bold'),
          local('Lovely-Font-Bold'),
          url('/fonts/ttinterfaces/TTInterfaces-Bold.otf')
          format('opentype');
  font-weight: 700;
}

/*bold version*/
@font-face {
  font-family: 'lovelyFont';
  src: url('/fonts/ttinterfaces/TTInterfaces-ExtraBold.ttf');
  src:
          local('Lovely Font Bold'),
          local('Lovely-Font-Bold'),
          url('/fonts/ttinterfaces/TTInterfaces-ExtraBold.otf')
          format('opentype');
  font-weight: 800;
}

h1 {
  font-size: 24px;
  font-weight: $medium;

  @media (min-width: 768px) {
    font-size: 40px;
  }
}

h2 {
  font-weight: $medium;
  font-size: 20px;
  line-height: 25px;
  color: $text-dark;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 36px;
  }
}

h3 {
  font-weight: $medium;
  font-size: 18px;
  line-height: 23px;
  color: $text-dark;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 36px;
  }
}

h5 {
  font-size: 20px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 24px;
  }
}


h6 {
  font-size: 18px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 21px;
  }
}

p {
  font-weight: $regular;
  font-size: 14px;
  line-height: 21px;
  color: $text-light;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
}
