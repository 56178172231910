.beta {
  font: {
    family: 'Caveat', cursive;
    size: 16px;
  }

  margin:{
    left: 30px;
    top: -10px;
  }
}
