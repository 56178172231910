@import "src/scss-commons/variables";
@import "src/scss-commons/utils";
@import "src/scss-commons/breakpoints";

.steps {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;

  &.block {
    display: block;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    margin: 0 auto;
    width: 650px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 992px) {
    width: 800px;
  }

  @include respond-below(sm) {
      background: none !important;
      box-shadow:none!important;
      border: 0!important;
  }


}
