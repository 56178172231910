@import "scss-commons/variables";
@import "scss-commons/typography";
@import "scss-commons/utils";



html, body {
  font-family: 'TTInterfaces', 'Roboto', sans-serif;
  background: rgb(251,251,251);
}



.btn {
  padding: 10px 30px;
  height: 40px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 16px;
  font-weight: $medium;
}

.btn-primary {
  background: $primary;
}

label {
  color: $text-dark;
}

.container {
  max-width: 1300px;
}




/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body::-webkit-scrollbar {
  background-color:#fff;
  width:16px
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color:#fff
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:4px solid #fff
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {display:none}

.form-control {
  width: 100%;
}

.form-control[readonly] {
  background-color: #f1f1f1 !important;
  opacity: 1 !important;
  outline: none;
  pointer-events: none;
}

.input-group-prepend.readonly span{
  background: #f1f1f1 !important;
}

.input-group-append.readonly span {
  background: #f1f1f1 !important;
}

.loader {
  width: 25px !important;
  height: 25px !important;
  margin-left: 25px;
  margin-top: -3px !important;
  background: transparent !important;
}
