@import "src/scss-commons/variables";

.saveButton {
  z-index: 999;
  border: none;
  background: $grey !important;
  color: $primary !important;
  padding: 10px 14px !important;
  box-shadow: none !important;
  top: 10px;
  right: 16px;

  >img{
    height: 80%;
  }

}
