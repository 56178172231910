@import 'src/scss-commons/variables.scss';
@import 'src/scss-commons/breakpoints.scss';

.userImg {
  border: {
    color: transparent !important;
    radius: 10px;
  }
  background: {
    repeat: no-repeat;
    size: cover;
    position: center center;
  }
  width: 100px;
  height: 130px;
  //box-shadow: inset 0 0 70px rgba(0, 0, 0, 0.9);
  overflow: hidden;
}

.uploadPhotoBtn {
  border: none !important;
  padding: 0!important !important;
  height: 100% !important;
  width: 100% !important;
  background: rgba($black,0.1)!important;
  box-shadow: none !important;
  opacity:0;

  &:active,
  &:hover {
    opacity:1;
    background: rgba($black,0.3)!important;
    box-shadow: none !important;
  }
}

.content {
  @include respond-above(sm) {
    flex-basis: 0;
  }
}

.right-box {
  padding-right: 20px;
  padding-left: 20px;
  @media (min-width: 768px) {
    padding-right: 130px;
    padding-left: 0;
  }
}

.both-box {
  padding-right: 20px;
  padding-left: 20px;
  @media (min-width: 768px) {
    padding-right: 130px;
    padding-left: 130px;
  }
}
