@import "src/scss-commons/variables";

.button {
  padding: 10px !important;
}

.card {
  height: 200px;
  padding: 24px 12px;

  > div {
    background: $primary;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    > i {
      background-color: $white;
      height: 50%;
      width: 50%;
    }
  }
}
