.beta{
  font-size: 0.8rem !important;
  top: 10px;
  right: 15px;

  span{
    font: {
      family: 'Caveat', cursive;
      size: 16px;
    }
  }

}
