.label {
  font-size: 0.9rem;
  @media (max-width: 768px) {
    margin-right: 20px;
  }
}

.textarea{
  min-height: 103px;
}

.dropdown {
  width: 100%;
  opacity: 1 !important;
  pointer-events: auto !important;
}

.dropdownItem {
  text-overflow: ellipsis;
  overflow:hidden;
}

.info{
  font-size: 1rem;
}
